import React, { useState } from 'react';

import ReactMarkdown from 'react-markdown';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {

  const presets = [
    { internalName: "imgur", linkText: "Imgur album" },
    { internalName: "thingiverse", linkText: "Enclosure model on Thingiverse" },
  ];

  const [entryLinks, setEntryLinks] = useState(presets.map(preset => ({ preset: true, linkText: preset.linkText, url: "" })));

  const [entryAuthorName, setEntryAuthorName] = useState("");
  const [entryAuthorLink, setEntryAuthorLink] = useState("");
  const [entryDescription, setEntryDescription] = useState("");

  const updateEntryLinkText = (idx, newLinkText) => {
    let newEntryLinks = [...entryLinks];
    newEntryLinks[idx].linkText = newLinkText;
    setEntryLinks(newEntryLinks);
  };

  const updateEntryLinkUrl = (idx, newUrl) => {
    let newEntryLinks = [...entryLinks];
    newEntryLinks[idx].url = newUrl;
    setEntryLinks(newEntryLinks);
  };

  const makeMarkdownLink = (text, url) => {
    if (url === undefined || url === "") {
      return text;
    }

    return `[${text}](${url})`;
  }

  const addEmptyLink = () => {
    let newEntryLinks = [...entryLinks]
    newEntryLinks.push({
      preset: false,
      linkText: "",
      url: "",
    });
    setEntryLinks(newEntryLinks);
  };

  let rawMarkdown = `## ${makeMarkdownLink(entryAuthorName, entryAuthorLink)}`;
  rawMarkdown += `\n\n**Links**:`;

  entryLinks.forEach((entry, idx) => {
    if (entry.linkText === "" || entry.url === "") {
      return;
    }

    rawMarkdown += ` ${makeMarkdownLink(entry.linkText, entry.url)} |`;
  });

  // cut away the last 2 chars: whitespace and |
  rawMarkdown = rawMarkdown.slice(0, -2);

  rawMarkdown += `\n\n${entryDescription}\n\n![${entryAuthorName}](./assets/community-builds/REPLACEME.jpg)\n`;

  return (
    <div className="App">
      <header className="App-header">

        <h2>Showcase entry adder</h2>
        <Form>
          <Form.Row>
            <Col>
              <Form.Label>Author details</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                value={entryAuthorName}
                placeholder="Author name"
                onChange={event => setEntryAuthorName(event.target.value)}
              />
              <Form.Control
                type="text"
                value={entryAuthorLink}
                placeholder="Author URL"
                onChange={event => setEntryAuthorLink(event.target.value)}
              />
            </Col>
          </Form.Row>

          <Form.Label>Links</Form.Label>
          {entryLinks.map((entry, idx) =>
            <Form.Row>
              <Col>
                <Form.Control
                  key={`${idx} -desc`}
                  disabled={entry.preset}
                  value={entry.linkText}
                  placeholder="Type a link name"
                  onChange={event => updateEntryLinkText(idx, event.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  key={`${idx} -url`}
                  value={entry.url}
                  placeholder="Paste URL"
                  onChange={event => updateEntryLinkUrl(idx, event.target.value)}
                />
              </Col>
            </Form.Row>
          )}
          <Form.Row>
            <Col>
              <Button variant="secondary" onClick={() => addEmptyLink()}>Add another link...</Button>
            </Col>
          </Form.Row>

          <Form.Label>Description (free markdown)</Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            value={entryDescription}
            onChange={event => setEntryDescription(event.target.value)}
          />

          <Form.Row>
            <Col>
              <Form.Label>Raw markdown</Form.Label>
            </Col>
          </Form.Row>

          <CopyToClipboard text={rawMarkdown}>
            <Button>Copy!</Button>
          </CopyToClipboard>

          <Form.Row>
            <Col>

              <Alert variant="dark">
                <ReactMarkdown source={"```markdown\n" + rawMarkdown + "\n```"} />
              </Alert>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>

              <Form.Label>Preview</Form.Label>
              <Alert variant="light">
                <ReactMarkdown source={rawMarkdown} />
              </Alert>
            </Col>
          </Form.Row>

        </Form>

      </header>
    </div >
  );
}

export default App;
